<template>
    <div class="container">
        <h1>Vídeos de {{$route.params.curso}}</h1>

        <!-- teste youtube -->
        <div class="row my-4">
            <div v-for="video in videos" v-bind:key="video.id" class="livro col-3 p-2">
                <a :href="video.link_acesso">
                    <img :src="video.link_capa">
                </a>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>


export default {
    name: 'Home',
    components: {
    },
    data () {
        return {
        modoDeCores: 'light',
        videos: [
                {
                    link_acesso: "https://www.youtube.com/watch?v=fWefml4Rmco&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/fWefml4Rmco/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBbGmYrfEHJApEDcr3wIpuWdJacqQ",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=1EYAK-JmQGc&t=3s&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/1EYAK-JmQGc/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB8-T05imxi1eAHBf8dFdnu2Nlo6Q",
                },
                {
                    link_acesso: "#",
                    link_capa: "https://i.ytimg.com/vi/Z06kjW8nG-0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBCa2kk3JgtIsJKdCpv4EYHmija8g",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=fUQoVnqZHY4&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/fUQoVnqZHY4/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDd_DQ1f8dshwUIc2jFtqMo3_VOXA",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=ZjxaCqjGiZk&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/ZjxaCqjGiZk/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCuJpdmKNUJj6VhoRXQ6y18XvnYTw",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=8oZjNUzFMLY&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/8oZjNUzFMLY/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBktDLmjhAv8NmagjWon2On8B4BNw",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=bMqlSSvoXS0&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/bMqlSSvoXS0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB0Q5SNV_0tmFI6-zZhwj9yJUfyDg",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=ClLIzPrmlKU&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/ClLIzPrmlKU/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCSKRc9vxIivqxSsccJUXn2gPDk8A",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=fWefml4Rmco&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/fWefml4Rmco/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBbGmYrfEHJApEDcr3wIpuWdJacqQ",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=1EYAK-JmQGc&t=3s&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/1EYAK-JmQGc/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB8-T05imxi1eAHBf8dFdnu2Nlo6Q",
                },
                {
                    link_acesso: "#",
                    link_capa: "https://i.ytimg.com/vi/Z06kjW8nG-0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBCa2kk3JgtIsJKdCpv4EYHmija8g",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=fUQoVnqZHY4&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/fUQoVnqZHY4/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDd_DQ1f8dshwUIc2jFtqMo3_VOXA",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=8oZjNUzFMLY&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/8oZjNUzFMLY/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBktDLmjhAv8NmagjWon2On8B4BNw",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=bMqlSSvoXS0&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/bMqlSSvoXS0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB0Q5SNV_0tmFI6-zZhwj9yJUfyDg",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=ClLIzPrmlKU&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/ClLIzPrmlKU/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCSKRc9vxIivqxSsccJUXn2gPDk8A",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=fWefml4Rmco&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/fWefml4Rmco/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBbGmYrfEHJApEDcr3wIpuWdJacqQ",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=1EYAK-JmQGc&t=3s&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/1EYAK-JmQGc/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB8-T05imxi1eAHBf8dFdnu2Nlo6Q",
                },
                {
                    link_acesso: "#",
                    link_capa: "https://i.ytimg.com/vi/Z06kjW8nG-0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBCa2kk3JgtIsJKdCpv4EYHmija8g",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=fUQoVnqZHY4&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/fUQoVnqZHY4/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDd_DQ1f8dshwUIc2jFtqMo3_VOXA",
                },

                {
                    link_acesso: "https://www.youtube.com/watch?v=8oZjNUzFMLY&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/8oZjNUzFMLY/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBktDLmjhAv8NmagjWon2On8B4BNw",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=bMqlSSvoXS0&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/bMqlSSvoXS0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB0Q5SNV_0tmFI6-zZhwj9yJUfyDg",
                },
                {
                    link_acesso: "https://www.youtube.com/watch?v=ClLIzPrmlKU&ab_channel=UNINOVE",
                    link_capa: "https://i.ytimg.com/vi/ClLIzPrmlKU/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCSKRc9vxIivqxSsccJUXn2gPDk8A",
                },

            ],
        }
    }
}
</script>


<style scoped>
.livro {
	transition: all .3s ease-in-out;
	padding: 10px 0;
}	
.livro:hover {
	transition: all .3s ease-in-out;
	-ms-transform: scale(1.4);
	-webkit-transform: scale(1.4);  
	transform: scale(1.1);
}
.livro img {  
	border-radius: 7px;
	box-shadow: var(--shadow) 0px 3px 8px;
	width: -webkit-fill-available;
}
.livro img:hover {  
	box-shadow: 0px 1px 5px 2px var(--shadow);
}
</style>